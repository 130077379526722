import React, { useEffect, useState, useRef } from "react";
import "./Login.css";
import axiosConfig from "../../Service/axiosConfig";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addWishlist } from "../../redux/Slices/cartSlice";

const Login = ({
  handleLoginEvent,
  setNumber,
  number,
  SetStep,
  Step,
  Setlogin,
  LoginAccount,
}) => {
  let navigate = useNavigate();
  const [userId, setUserId] = useState();
  const [timeLeft, setTimeLeft] = useState(30);
  const [isExpired, setIsExpired] = useState(false);
  const [Data, SetData] = useState({
    mobile: "",
    otp: "",
  });
  const [otps, setOtps] = useState([
    { id: 1, value: "" },
    { id: 2, value: "" },
    { id: 3, value: "" },
    { id: 4, value: "" },
  ]);
  const isSubmitDisabled = !otps.every((otp) => otp.value);
  const [error, SetError] = useState("");
  const InputHandleer = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    SetData({ ...Data, [name]: value });
  };

  const inputRefs = useRef([]);

  const handleChange = (e, id) => {
    const { value } = e.target;
    // Only allow single digit inputs (0-9)
    if (/^\d?$/.test(value)) {
      // Update the OTP value for the specific input
      setOtps((prevOtps) =>
        prevOtps.map((otp) => (otp.id === id ? { ...otp, value } : otp))
      );
      // Move focus to the next input if the current one is filled
      if (value && id < otps.length) {
        inputRefs.current[id].focus();
      }
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const otpValues = otps.map((otp) => otp.value).join("");
    console.log("OTP Submitted: ", otpValues);
    // You can now use otpValues for verification
  };
  const [loginSuccess, SetloginSuccess] = useState(false);
  const ValidateMobile = (e) => {
    e.preventDefault();
    setNumber({ ...number, mobile: Data.mobile });
    SetError(""); // Clear error before validation
    if (Data.mobile.length < 10 || Data.mobile.length > 10) {
      SetError("Enter A Valid Mobile number");
    } else {
      let domain = window.location.hostname.replace("www.", "");
      axiosConfig
        .post("/accounts/validate_mobile/", {
          mobile: Data.mobile,
          verify_domain: domain === "localhost" ? "uat.opoto.in" : domain,
        })
        .then((res) => {
          SetStep(2);
          console.log(res.data.message);
          SetError("");
          setTimeLeft(30);
          setOtps([
            { id: 1, value: "" },
            { id: 2, value: "" },
            { id: 3, value: "" },
            { id: 4, value: "" },
          ]);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      setIsExpired(true);
    }
  }, [timeLeft]);

  const handleResendClick = (e) => {
    ValidateMobile(e);
    setTimeLeft(30);
    setIsExpired(false);
  };
  const VerifyOtp = (e) => {
    e.preventDefault();
    axiosConfig
      .post("/accounts/verify_otp/", {
        mobile: Data.mobile,
        otp: otps.map((otp) => otp.value).join(""),
      })
      .then((res) => {
        console.log(res, "otp posted");
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userId", res.data.user_id);
        setUserId(res.data.user_id);
        GetData(res.data.user_id);
        // localStorage.setItem('wishlists', JSON.stringify(res.data.results));
        localStorage.setItem("number", Data.mobile);
        SetloginSuccess(true);
        handleLoginEvent();
        Setlogin && Setlogin(!LoginAccount);
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.message === "Verification failed.") {
          SetError("Invalid OTP. Please try again.");
        }
      });
  };
  let page = 1;
  const dispatch = useDispatch();
  // const[isDeleted,setIsDeleted]=useState(false)
  const GetData = async (user) => {
    console.log("inside get data");
    try {
      const response = await axiosConfig.get(
        `/catlog/wishlists/?page_size=20&page=${page}&user=${user}`
      );
      console.log(response.data.results);
      response.data.results.map((item) => {
        dispatch(addWishlist({ productId: item.product.id, wish_id: item.id }));
      });
      // setIsDeleted(false)
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect(() => {
  //     userId && GetData(userId);
  // },[isDeleted, userId])
  return (
    <>
      {loginSuccess ? (
        <>
          <h4 className="login-success">Login Successful</h4>
        </>
      ) : (
        <>
          {Step == "1" ? (
            <form className="login-form">
              {/* <p className="">Enter your phone number to </p>
                            <p className="">Login/Sign up</p> */}
              <div className="login-header-main">
                <div className="login-help weight--semibold">
                  Enter your phone number to
                </div>
                <div className="login-help weight--semibold">Login/Sign up</div>
              </div>
              <div className="form-group input-prefix">
                <span className="prefix me-2">+91</span>
                <input
                  name="mobile"
                  className="num-input"
                  type="number"
                  placeholder="Mobile Number"
                  value={Data.mobile}
                  onChange={InputHandleer}
                  onWheel={(e) => e.target.blur()}
                  style={{ flex: 1 }}
                />
                {/* <div className="color-error">{error && error}</div> */}
              </div>

              <button
                type="submit"
                className="btn btn-next"
                disabled={Data.mobile.length !== 10}
                onClick={ValidateMobile}
              >
                Next
              </button>
            </form>
          ) : (
            <form className="otp-form">
              <p className="">Enter 4 digit code sent to your phone</p>
              <p>+91-{Data.mobile}</p>
              <div className="fullCentered mb-3">
                {otps.map((otp, index) => (
                  <input
                    type="text"
                    key={otp.id}
                    ref={(el) => (inputRefs.current[index] = el)} // Assigning ref to each input
                    name={`otp-${otp.id}`}
                    className="form-control otp-input"
                    maxLength={1}
                    value={otp.value}
                    onChange={(e) => handleChange(e, otp.id)}
                    autoFocus={index === 0}
                  />
                ))}
              </div>
              <div className="color-error">{error && error}</div>
              <button
                type="submit"
                className="btn btn-next"
                disabled={isSubmitDisabled}
                onClick={VerifyOtp}
              >
                Submit
              </button>
              <div
                style={{
                  fontFamily: "Arial, sans-serif",
                  fontSize: "20px",
                  margin: "20px",
                }}
              >
                {!isExpired ? (
                  <span>Resend OTP in {timeLeft} sec</span>
                ) : (
                  <span
                    onClick={handleResendClick}
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      color: "var(--primary_color)",
                    }}
                  >
                    ResendOTP
                  </span>
                )}
              </div>
            </form>
          )}
        </>
      )}
    </>
  );
};
export { Login };
