import React, { useState, useEffect } from "react";
import "./MainBanner.css";
import axiosConfig from "../../Service/axiosConfig";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";

const MainBanners = () => {
  const [Data, SetData] = useState([]);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "red" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "none", background: "green" }}
        onClick={onClick}
      />
    );
  }
  var settings = {
    className: "center",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const GetData = async () => {
    try {
      const responce = await axiosConfig.get(
        "/catlog/banners/?banner_type=full_width&is_suspended=false"
      );
      SetData(responce.data.results);
      console.log(responce);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetData();
    console.log("banner data", Data);
  }, []);

  return (
    <div className="text-center">
      <Slider {...settings}>
        {Data.length > 0 &&
          Data.map((item, index) => {
            return (
              <div className="container fullwidth-banner" key={index}>
                <NavLink to={item.slug ? item.slug : "#"}>
                  <img
                    src={item.image ? item.image.image : item.image}
                    className="main-banner"
                    alt="banner"
                  />
                </NavLink>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};
export { MainBanners };
